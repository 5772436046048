import { PageWithNavigationAndSectionPadding } from '@/src/components';
import React from 'react';
import * as S from './BatteryBoostZohoForm.styled';
import { useAccountContext } from '@ovotech/ui-tools';
import { getQueryParamValue } from '@/src/utils/getQueryParamValue';

export const BatteryBoostZohoForm = () => {
  const { accountId } = useAccountContext();
  const dealid = getQueryParamValue('dealid');
  const batteryBoostRegisterInterestZohoUrl = new URL(
    `https://survey.zohopublic.eu/zs/TsDXaZ?accountId=${accountId}`,
  );

  if (dealid) {
    batteryBoostRegisterInterestZohoUrl.searchParams.append('dealid', dealid);
  }

  return (
    <PageWithNavigationAndSectionPadding title="Battery Boost">
      <S.Iframe
        title="Zoho form"
        src={batteryBoostRegisterInterestZohoUrl.toString()}
      />
    </PageWithNavigationAndSectionPadding>
  );
};
