import { Heading4, Heading1 } from '@ovotech/nebula';
import styled from 'styled-components';

export const HeaderTitle = styled(Heading1)`
  color: white;
  margin-top: 2.3rem !important;
  font-size: clamp(2.5rem, 2vw + 1rem, 3.8rem) !important;
`;

export const HeaderDescription = styled(Heading4)`
  color: white;
  margin-bottom: 25px !important;
`;
